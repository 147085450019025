// TempData
import TempDataNavBar from '../TempData_NavBar';
import TempDataMegaMenu from '../TempData_MegaMenu';
import TempDataFooter from '../TempData_footer';
import * as ColorGuide from '../../../cssGuides/colorGuide';

// Page Data
const Data = {
  pageName: 'TextArea Test Page',
  pageTemplate: 'Template3',
  pageData: {
    nav: TempDataNavBar,
    megaMenu: TempDataMegaMenu,
    footer: TempDataFooter,
    level_1_Id: 'Page_1',
    pageTitle: 'HTML render test page',
    pageDescription: 'Tester component - HTML render',
    bannerImage: null, // accepts null
    bannerContent: '<h1>Banner text accepts HTML</h1>', // accepts null
    bottomContent: [
      {
        componentName: 'BlockArea',
        componentData: {
          backgroundColor: ColorGuide.aqua,
        },
        componentChildren: [
          {
            componentName: 'TextArea',
            componentData: {
              color: ColorGuide.lightGray,
            },
            componentChildren: [
              {
                componentName: 'HtmlRender',
                componentData: {
                  html:
                    '<h2>Ikke alle er dækket</h2><p>En mindre gruppe kunder er ikek dækket ved sygdom - det gælder blandt andet de fleste kunder ansat i fleksjob elle r under andre særlige nsættelsesforhold.</p>',
                },
              },
            ],
          },
        ],
      },
    ],
    desktopTools: {}, // accepts null
  },
  pageContent: [
    {
      componentName: 'TextArea',
      componentData: {
        color: ColorGuide.lightGray,
      },
      componentChildren: [
        {
          componentName: 'HtmlRender',
          componentData: {
            html:
              '<h2>Ikke alle er dækket</h2><p>En mindre gruppe kunder er ikek dækket ved sygdom - det gælder blandt andet de fleste kunder ansat i fleksjob elle r under andre særlige nsættelsesforhold.</p>',
          },
        },
      ],
    },
  ],
};

export default Data;
